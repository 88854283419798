import React from "react";
import Layout from "../components/layout";
import styles from "../styles/pages.module.css";

export default function Pii() {
	return (
		<Layout>
			<div className={styles.bodyText}>
				<h4>Personal Identifiable Information (PII) Policy</h4>
				<span>
					<i>Effective Date: 1-Dec-2024</i>
				</span>
				<br />
				<br />
				<p>
					This Personal Information Policy outlines how we collect,
					use, and protect personally identifiable information (PII)
					on our website <b>DebjyotiAcharjee.com</b>.
				</p>
				<ol>
					<li>
						<b>Information We Collect: </b> We may collect the
						following types of PII:
						<ul>
							<li>Name</li>
							<li>Email address</li>
							<li>
								Any other information you voluntarily provide
							</li>
						</ul>
					</li>
					<li>
						<b>How We Use Your Information: </b> We may use the
						information we collect for various purposes, including:
						<ul>
							<li>To communicate with you</li>
							<li>
								To respond to your inquiries To improve our
								website and services
							</li>
							<li>
								To send you updates and promotional materials
							</li>
						</ul>
					</li>
					<li>
						<b>Data Protection: </b> We implement reasonable
						security measures to protect your PII from unauthorized
						access, use, or disclosure. However, no method of
						transmission over the internet or electronic storage is
						100% secure.
					</li>

					<li>
						<b>Third-Party Disclosure: </b> We will not sell, trade,
						or otherwise transfer your PII to outside parties
						without your consent, except to comply with the law or
						protect our rights.
					</li>
					<li>
						<b>Your Rights: </b> You have the right to:
						<ul>
							<li>
								Access the personal information we hold about
								you
							</li>
							<li>Request correction of your PII</li>
							<li>Request deletion of your PII</li>
							<li>
								Opt-out of receiving marketing communications
							</li>
						</ul>
					</li>
					<li>
						<b>Cookies and Tracking Technologies: </b> Our website
						may use cookies and similar tracking technologies to
						enhance your experience. You can manage your cookie
						preferences through your browser settings.
					</li>
					<li>
						<b>Children’s Privacy: </b> Our website is not intended
						for children under the age of 13. We do not knowingly
						collect PII from children. If we become aware that we
						have collected such information, we will take steps to
						delete it.
					</li>
					<li>
						<b>Changes to This Policy: </b> We may update this
						Personal Information Policy from time to time. Any
						changes will be posted on this page with an updated
						effective date.
					</li>
					<li>
						<b>Contact Us: </b> If you have any questions about this
						policy or our practices regarding your PII, please
						contact us at: <i>itsme@debjyotiacharjee.com</i>
					</li>
				</ol>
			</div>
		</Layout>
	);
}
